(function (win, $) {
    'use strict';

    // Trigger live chat
    const doc = win.document,
        $win = $(win),
        $doc = $(doc),
        $body = $('body'),
        $grid = $('main .grid').first(),
        $footer = $('#main-footer'),
        statusUrl = $('#_lcstatuslink').prop('href') || $body.data('livechatstatus'),
        hideCbButton = true,
        cbCustEltName = 'chatbeacon-button',
        beaconBtnSelector = '#beaconBtn',
        cookieName = 'dkusechat',
        cookieVal = '1',
        cookieHours = 2,
        clickEventName = 'click.livechat',
        chatScriptUrl = 'https://livechat.niddk.nih.gov/chatbeacon/NIDDK/1/scripts/chatbeacon.js?accountId=1&siteId=1&queueId=1&m=0&i=1&b=1&c=1&theme=frame';
    let cbButton, cbCustomElt, isOnline, showBadge, noBadge, loadChatScriptCalled,
        $floating = $('.livechat-floating').first(),
        $badge = $('.livechat-badge', $floating),
        $buttons = $('[data-trigger-chat]');

    function getCookies() {
        return document.cookie.split(/; ?/)
            .map(s => s.split('='))
            .reduce((o, v) => (o[v[0]] = v[1], o), {});
    }

    function setCookie(name, val) {
        val = val || '';
        const d = new Date();
        d.setTime(d.getTime() + ((val ? cookieHours : -240) * 60 * 60 * 1000));
        document.cookie = name + '=' + val + ';path=/;expires=' + d.toUTCString();
    }

    // Determine if the "actively chatting" cookie is set
    function isUsingChat() {
        const cookies = getCookies();

        // remove legacy cookie if found
        const oldCookie = 'dkchatting';
        if (cookies[oldCookie]) setCookie(oldCookie, '');

        return cookies[cookieName] === cookieVal;
    }

    // Set a cookie to indicate that the user has activated chat so that it can automatically
    // load on subsequent pages
    function setUsingChat(clearCookie) {
        if (!!clearCookie !== isUsingChat()) return; // !! to coerce values to boolean
        setCookie(cookieName, clearCookie ? '' : cookieVal);
    }

    // Handle positioning of floating Live Chat badge
    function badgePosition() {
        const left = $grid[0].getBoundingClientRect().left,
            padding = parseInt($grid.css('paddingLeft')),
            badgeWidth = $badge.width();

        if ($doc.scrollTop() + win.innerHeight >= $footer.offset().top) {
            (win.innerWidth >= 1200) ? $badge.css({'position': 'absolute', 'right': left - badgeWidth}) : $badge.css({'position': 'absolute', 'right': padding * (2/3) });
        } else {
            (win.innerWidth >= 1200) ? $badge.css({'position': 'fixed', 'right': left - badgeWidth }) : $badge.css({'position': 'fixed', 'right': padding * (2/3) });
        }
    }

    // Update the online state of the buttons
    function updateButtonOnlineState() {
        if (isOnline) {
            $body.addClass('livechat-online');
            $buttons.attr({'aria-label': 'Open live chat window', 'title': 'Start a chat with us'});
        } else {
            $buttons.attr({'aria-label': 'Open a window to write us', 'title': 'Write us'});
        }
    }

    // Enable triggering the chat provider's event handler once it is loaded
    function onChatProviderLoaded(fromClick) {
        if (!cbCustomElt) return;

        updateButtonOnlineState();

        function findAndTriggerButton(e) {
            if (e && (e.ctrlKey !== e.metaKey) && e.shiftKey) {
                e.preventDefault();
                setUsingChat(true);
                // eslint-disable-next-line no-console
                console.log(cookieName + ' cleared');
                return false;
            }
            const isPanelOpen = () => !!document.querySelector('#chatBeaconCtrl.open');
            // Keep looking for the button until it is found
            cbButton = cbButton || cbCustomElt.shadowRoot ? cbCustomElt.shadowRoot.querySelector(beaconBtnSelector) : null;
            if (cbButton) {
                if (e) e.preventDefault();
                if (hideCbButton) cbButton.classList.add('hide');
                setUsingChat();
                cbButton.click();
                if (!isPanelOpen()) {
                    let clicks = 1, i = 0;
                    const int = win.setInterval(() => {
                        i++;
                        let isOpen = isPanelOpen();
                        if (isOpen || i > 6) { // Only allow 6 tries
                            win.clearInterval(int);
                            // eslint-disable-next-line no-console
                            console.log('cb button clicked ' + clicks + ' times; opened: ' + isOpen);
                            return;
                        }
                        if (!isOpen) {
                            cbButton.click(); // Click again if panel isn't opened
                            clicks++;
                        }
                    }, 250);
                } else {
                    // eslint-disable-next-line no-console
                    console.log('cb button clicked 1 time; opened: true');
                }
                if (e) return false;
            } else {
                // eslint-disable-next-line no-console
                console.log('cb button not found');
            }
        }

        // Re-bind the chat trigger buttons to open the chat window
        $body.addClass('livechat-loaded')
            .off(clickEventName).on(clickEventName, '[data-trigger-chat]', findAndTriggerButton);

        // If the user clicked a chat trigger button, launch the chat window immediately
        if (fromClick) findAndTriggerButton();
    }

    // Update the live chat status classes and adjust the markup and events
    function updateChatStatus() {
        if (showBadge) $body.addClass('livechat-showbadge');

        updateButtonOnlineState();

        if (showBadge) {
            badgePosition();
            $win.off('dk:widthChanged.chatbadge').on('dk:widthChanged.chatbadge', function(_, {layoutChanged, isMobile}) {
                if (layoutChanged) {
                    if (isMobile) {
                        $('.livechat-floating .livechat-badge').css({'display': 'block', 'position': 'fixed', 'left': '', 'right': ''});
                        $win.off('scroll.chatbadge');
                    } else {
                        badgePosition();
                        $win.on('scroll.chatbadge', function () { badgePosition(); });
                    }
                } else if (!isMobile) {
                    badgePosition();
                }
            });
        } else {
            $('.livechat-floating').remove();
            noBadge = true;
        }
    }

    function loadChatScript(fromClick) {
        // Ensure this method is only called once per page
        if (loadChatScriptCalled) return;
        loadChatScriptCalled = true;

        // Determine if the chat script is already loaded, and if not, watch for the button to be added to the DOM
        if ((cbCustomElt = doc.querySelector(cbCustEltName))) {
            cbButton = cbButton || cbCustomElt.shadowRoot ? cbCustomElt.shadowRoot.querySelector(beaconBtnSelector) : null;
            if (cbButton && hideCbButton) cbButton.classList.add('hide');
            onChatProviderLoaded(fromClick);
        } else {
            const MO = win.MutationObserver || win.WebKitMutationObserver;
            if (MO) {
                let observer = new MO(mutations => {
                    mutations.forEach(mutation => {
                        let nodes = Array.prototype.slice.call(mutation.addedNodes);
                        const custElt = nodes.filter(node => node.localName.toLowerCase() === cbCustEltName)[0];
                        if (custElt) {
                            if (observer) observer.disconnect();
                            observer = null;
                            cbCustomElt = custElt;
                            cbButton = cbButton || cbCustomElt.shadowRoot ? cbCustomElt.shadowRoot.querySelector(beaconBtnSelector) : null;
                            if (cbButton && hideCbButton) cbButton.classList.add('hide');
                            onChatProviderLoaded(fromClick);
                        }
                    });
                });
                observer.observe(doc.body, { childList: true });
            }
        }

        // Inject the live chat script into the DOM
        let s = document.createElement('script');
        s.defer = true;
        s.src = chatScriptUrl;
        document.body.appendChild(s);
    }

    if (statusUrl) {
        // Only set up live chat if the chat status URL is available
        fetch(statusUrl).then(r => r.json())
            .then(data => {
                isOnline = (data.online || data['Online']) ? true : false;
                showBadge = (data.badge || data.badgeEnabled || data['Badge'] || data['BadgeEnabled']) ? true : false;
                updateChatStatus();
                try {
                    (win.dataLayer = win.dataLayer || []).push({
                        liveChat: {
                            status: isOnline ? 'Live' : 'Offline',
                            badge: showBadge
                        }
                    });
                } catch (_) { /* ignore */ }
            })
            .catch(() => {
                updateChatStatus();
            });
    } else {
        $('.livechat-floating').remove();
        noBadge = true;
    }

    // On document loaded
    $(function () {
        const $footerChatIcon = $('.livechat-source .livechat-badge').first();
        if (!noBadge && !$floating.length && $footerChatIcon.length) {
            // The floating badge is not on the page; clone it from the .livechat-source
            $floating = $('<div class="livechat-floating"></div>');
            $badge = $footerChatIcon.clone().removeAttr('style');
            // Fix SVG gradient IDs to avoid duplication on the page
            $badge.find('linearGradient[id]').each(function () {
                const $grad = $(this), origId = $grad.attr('id'), newId = win.dk.generateId('svg');
                $grad.attr('id', newId);
                $badge.find('[fill]').each(function () {
                    const $elt = $(this), fill = $elt.attr('fill') || '';
                    if (fill.startsWith('url(#') && fill.indexOf(origId) >= 0) {
                        $elt.attr('fill', fill.replace(origId, newId));
                    }
                });
            });
            $('main').append($floating.append($badge));
            $buttons = $buttons.add($badge);
            badgePosition();
        }
        if (isUsingChat()) {
             // Load live chat scripts when chat has previously been launched.
             // This allows continued chat sessions if the user navigates while chatting.
            loadChatScript();
        } else {
            $body.off(clickEventName).on(clickEventName, '[data-trigger-chat]', function (e) {
                e.preventDefault();
                loadChatScript(true); // 'true' argument indicates a click, so that chat is immediately triggered
                return false;
            });
        }
    });
})(window, window.jQuery);
