(function (win, $) {
    'use strict';

    var dk = win.dk,
        // Links matching the following selectors will be excluded
        excludedLinkSelectors = [
            '.eld',
            '.no-exit-link',
            '.expand'
        ].join(','),
        // Links with parent elements matching the following selectors will be excluded
        excludedParentSelectors = [
            '.no-exit-link',
            '.addthis_toolbox',
            '.recaptchatable',
            '.twitter-tweet',
            '.embed'
        ].join(','),
        // Links matching the following selectors will still be treated as exit links but won't
        // display the disclaimer icon
        noIconLinkSelectors = [
            '.no-disclaimer',
            '.cta',
            '.dk-cta',
            '.dk-box > a:first-child',
            '.dk-box.border-thick[class*=accent-] h2 a'
        ].join(','),
        noIconParentSelectors = [
            '.no-disclaimer',
            '.dk-card',
            '.dk-card-row',
            '.sc-card',
            '.dk-suite-nav',
            '.previous-next',
            '.contact-buttons',
            '.dk-share-buttons',
            '.dk-tabnav',
            '.social-contact-links'
        ].join(','),
        // Links with hostnames that end with the following domains will be treated as internal org links
        internalDomains = [
            $('<a/>').attr('href', location.href).prop('hostname'), // Current host
            'niddk.nih.gov',
            'betterdiabetescare.nih.gov',
            'bowelcontrol.nih.gov',
            'celiac.nih.gov',
            'hnrim.nih.gov',
            'mammary.nih.gov',
            'ndep.nih.gov',
            'nkdep.nih.gov',
            't1diabetes.nih.gov',
            'yourdiabetesinfo.org',
            'diabetescommittee.gov'
        ].join('|'),
        // Links with hostnames that end with the following domains will be treated as NIH links
        nihDomains = [
            'cancer.gov',
            'clinicaltrials.gov',
            'drugabuse.gov',
            'genome.gov',
            'medlineplus.gov',
            'nih.gov'
        ].join('|'),
        // Determines whether to render the NIH-specific exit link icon
        showNihIcon = true,
        nihText = {en: 'NIH external link', es: 'Enlace externo del NIH', fr: 'Lien externe du NIH'},
        textMap = {
            text: {en: 'External link', es: 'Enlace externo', fr: 'Lien externe'},
            title: {en: 'View NIDDK\'s external link disclaimer', es: 'Ver descargo de responsabilidad de los enlaces externos del NIDDK', fr: 'Afficher la clause de non-responsabilité du NIDDK concernant les liens externes'},
            nihText: nihText,
            nihTitle: nihText
        },
        internalHostRegex = new RegExp('(' + internalDomains.replace('.', '\\.') + ')$', 'i'),
        nihHostRegex = new RegExp('(' + nihDomains.replace('.', '\\.') + ')$', 'i');

    function isString(str) {
        return typeof str === 'string';
    }

    function startsWith(str, val) {
        return isString(str) && isString(val) && str.slice(0, val.length) === val;
    }

    function isInternalLink($link) {
        var hn = isString($link) ? $link : $link.prop('hostname') || '';
        return hn.match(internalHostRegex) ? true : false;
    }

    function isNihLink($link) {
        var hn = isString($link) ? $link : $link.prop('hostname') || '';
        return hn.match(nihHostRegex) ? true : false;
    }

    function evaluateExitLinks(parent) {
        if (dk.isEditing) return;
        var lang = dk.lang;

        // Identify links to process the handle each of them
        $(parent || 'main').find('a[href]').filter(function () {
            var $me = $(this), proto = $me.prop('protocol');
            return !startsWith(proto, 'javascript') &&
                !startsWith(proto, 'mailto') &&
                !startsWith(proto, 'tel') &&
                !$me.is(excludedLinkSelectors) &&
                !$me.parents().is(excludedParentSelectors) &&
                !isInternalLink($me);
        }).each(function () {
            var $me = $(this),
                $icon = null,
                isNih = isNihLink($me),
                title;
            $me.addClass('exit-link');
            if (!isNih) {
                $me.attr({target: '_blank', rel: 'noopener'});
            }
            if (!$me.is(noIconLinkSelectors) && !$me.parents().is(noIconParentSelectors) && !$me.next().is('.eld')) {
                if (isNih && showNihIcon) {
                    $icon = $('<i/>').addClass('eld i-exit-nih').text(textMap.nihText[lang]);
                    title = textMap.nihTitle[lang];
                    if (title) $icon.attr('title', title);
                }
                if (!isNih) {
                    $icon = $('<a/>').attr('href', dk.pages.disclaimers).addClass('eld i-exit').text(textMap.text[lang]);
                    title = textMap.title[lang];
                    if (title) $icon.attr('title', title);
                }
                if ($icon) $me.after(' ', $icon);
            }
        });
    }

    dk.evaluateExitLinks = evaluateExitLinks;

    // Document ready
    if (!dk.isEditing) $(function () { evaluateExitLinks(); });
})(window, window.jQuery);
